const countryData = [
  { ID: 1, Name: "中国(大陆)" },
  { ID: 2, Name: "中国(香港)" },
  { ID: 3, Name: "中国(澳门)" },
  { ID: 4, Name: "中国(台湾)" },
  { ID: 5, Name: "其他国家及地区" },
];
const provinceData = [
  { Country: 1, ID: 1, Name: "北京" },
  { Country: 1, ID: 2, Name: "吉林省" },
  { Country: 1, ID: 3, Name: "黑龙江省" },
  { Country: 1, ID: 4, Name: "上海" },
  { Country: 1, ID: 5, Name: "江苏省" },
  { Country: 1, ID: 6, Name: "浙江省" },
  { Country: 1, ID: 7, Name: "安徽省" },
  { Country: 1, ID: 8, Name: "福建省" },
  { Country: 1, ID: 9, Name: "江西省" },
  { Country: 1, ID: 10, Name: "山东省" },
  { Country: 1, ID: 11, Name: "河南省" },
  { Country: 1, ID: 12, Name: "湖北省" },
  { Country: 1, ID: 13, Name: "湖南省" },
  { Country: 1, ID: 14, Name: "广东省" },
  { Country: 1, ID: 15, Name: "广西壮族自治区" },
  { Country: 1, ID: 16, Name: "海南省" },
  { Country: 1, ID: 17, Name: "重庆" },
  { Country: 1, ID: 18, Name: "四川省" },
  { Country: 1, ID: 19, Name: "贵州省" },
  { Country: 1, ID: 20, Name: "云南省" },
  { Country: 1, ID: 21, Name: "西藏自治区" },
  { Country: 1, ID: 22, Name: "陕西省" },
  { Country: 1, ID: 23, Name: "甘肃省" },
  { Country: 1, ID: 24, Name: "青海省" },
  { Country: 1, ID: 25, Name: "宁夏回族自治区" },
  { Country: 1, ID: 26, Name: "新疆维吾尔自治区" },
  { Country: 4, ID: 27, Name: "台湾省" },
  { Country: 2, ID: 28, Name: "香港特别行政区" },
  { Country: 3, ID: 29, Name: "澳门特别行政区" },
  { Country: 5, ID: 30, Name: "海外" },
  { Country: 1, ID: 31, Name: "天津" },
  { Country: 1, ID: 32, Name: "辽宁省" },
  { Country: 1, ID: 33, Name: "内蒙古自治区" },
  { Country: 1, ID: 34, Name: "山西省" },
  { Country: 1, ID: 35, Name: "河北省" },
  { Country: 2, ID: 36, Name: "其他" },
];
const cityData = [
  { Province: 1, ID: 1, Name: "北京市" },
  { Province: 2, ID: 2, Name: "长春市" },
  { Province: 2, ID: 3, Name: "吉林市" },
  { Province: 2, ID: 4, Name: "四平市" },
  { Province: 2, ID: 5, Name: "辽源市" },
  { Province: 2, ID: 6, Name: "通化市" },
  { Province: 2, ID: 7, Name: "白山市" },
  { Province: 2, ID: 8, Name: "松原市" },
  { Province: 2, ID: 9, Name: "白城市" },
  { Province: 2, ID: 10, Name: "延边朝鲜族自治州" },
  { Province: 3, ID: 11, Name: "哈尔滨市" },
  { Province: 3, ID: 12, Name: "齐齐哈尔市" },
  { Province: 3, ID: 13, Name: "鸡西市" },
  { Province: 3, ID: 14, Name: "鹤岗市" },
  { Province: 3, ID: 15, Name: "双鸭山市" },
  { Province: 3, ID: 16, Name: "大庆市" },
  { Province: 3, ID: 17, Name: "伊春市" },
  { Province: 3, ID: 18, Name: "佳木斯市" },
  { Province: 3, ID: 19, Name: "七台河市" },
  { Province: 3, ID: 20, Name: "牡丹江市" },
  { Province: 3, ID: 21, Name: "黑河市" },
  { Province: 3, ID: 22, Name: "绥化市" },
  { Province: 3, ID: 23, Name: "大兴安岭地区" },
  { Province: 4, ID: 24, Name: "上海市" },
  { Province: 5, ID: 25, Name: "南京市" },
  { Province: 5, ID: 26, Name: "无锡市" },
  { Province: 5, ID: 27, Name: "徐州市" },
  { Province: 5, ID: 28, Name: "常州市" },
  { Province: 5, ID: 29, Name: "苏州市" },
  { Province: 5, ID: 30, Name: "南通市" },
  { Province: 5, ID: 31, Name: "连云港市" },
  { Province: 5, ID: 32, Name: "淮安市" },
  { Province: 5, ID: 33, Name: "盐城市" },
  { Province: 5, ID: 34, Name: "扬州市" },
  { Province: 5, ID: 35, Name: "镇江市" },
  { Province: 5, ID: 36, Name: "泰州市" },
  { Province: 5, ID: 37, Name: "宿迁市" },
  { Province: 6, ID: 38, Name: "杭州市" },
  { Province: 6, ID: 39, Name: "宁波市" },
  { Province: 6, ID: 40, Name: "温州市" },
  { Province: 6, ID: 41, Name: "嘉兴市" },
  { Province: 6, ID: 42, Name: "湖州市" },
  { Province: 6, ID: 43, Name: "绍兴市" },
  { Province: 6, ID: 44, Name: "金华市" },
  { Province: 6, ID: 45, Name: "衢州市" },
  { Province: 6, ID: 46, Name: "舟山市" },
  { Province: 6, ID: 47, Name: "台州市" },
  { Province: 6, ID: 48, Name: "丽水市" },
  { Province: 7, ID: 49, Name: "合肥市" },
  { Province: 7, ID: 50, Name: "芜湖市" },
  { Province: 7, ID: 51, Name: "蚌埠市" },
  { Province: 7, ID: 52, Name: "淮南市" },
  { Province: 7, ID: 53, Name: "马鞍山市" },
  { Province: 7, ID: 54, Name: "淮北市" },
  { Province: 7, ID: 55, Name: "铜陵市" },
  { Province: 7, ID: 56, Name: "安庆市" },
  { Province: 7, ID: 57, Name: "黄山市" },
  { Province: 7, ID: 58, Name: "滁州市" },
  { Province: 7, ID: 59, Name: "阜阳市" },
  { Province: 7, ID: 60, Name: "宿州市" },
  { Province: 7, ID: 61, Name: "六安市" },
  { Province: 7, ID: 62, Name: "亳州市" },
  { Province: 7, ID: 63, Name: "池州市" },
  { Province: 7, ID: 64, Name: "宣城市" },
  { Province: 8, ID: 65, Name: "福州市" },
  { Province: 8, ID: 66, Name: "厦门市" },
  { Province: 8, ID: 67, Name: "莆田市" },
  { Province: 8, ID: 68, Name: "三明市" },
  { Province: 8, ID: 69, Name: "泉州市" },
  { Province: 8, ID: 70, Name: "漳州市" },
  { Province: 8, ID: 71, Name: "南平市" },
  { Province: 8, ID: 72, Name: "龙岩市" },
  { Province: 8, ID: 73, Name: "宁德市" },
  { Province: 9, ID: 74, Name: "南昌市" },
  { Province: 9, ID: 75, Name: "景德镇市" },
  { Province: 9, ID: 76, Name: "萍乡市" },
  { Province: 9, ID: 77, Name: "九江市" },
  { Province: 9, ID: 78, Name: "新余市" },
  { Province: 9, ID: 79, Name: "鹰潭市" },
  { Province: 9, ID: 80, Name: "赣州市" },
  { Province: 9, ID: 81, Name: "吉安市" },
  { Province: 9, ID: 82, Name: "宜春市" },
  { Province: 9, ID: 83, Name: "抚州市" },
  { Province: 9, ID: 84, Name: "上饶市" },
  { Province: 10, ID: 85, Name: "济南市" },
  { Province: 10, ID: 86, Name: "青岛市" },
  { Province: 10, ID: 87, Name: "淄博市" },
  { Province: 10, ID: 88, Name: "枣庄市" },
  { Province: 10, ID: 89, Name: "东营市" },
  { Province: 10, ID: 90, Name: "烟台市" },
  { Province: 10, ID: 91, Name: "潍坊市" },
  { Province: 10, ID: 92, Name: "济宁市" },
  { Province: 10, ID: 93, Name: "泰安市" },
  { Province: 10, ID: 94, Name: "威海市" },
  { Province: 10, ID: 95, Name: "日照市" },
  { Province: 10, ID: 96, Name: "莱芜市" },
  { Province: 10, ID: 97, Name: "临沂市" },
  { Province: 10, ID: 98, Name: "德州市" },
  { Province: 10, ID: 99, Name: "聊城市" },
  { Province: 10, ID: 100, Name: "滨州市" },
  { Province: 10, ID: 101, Name: "菏泽市" },
  { Province: 11, ID: 102, Name: "郑州市" },
  { Province: 11, ID: 103, Name: "开封市" },
  { Province: 11, ID: 104, Name: "洛阳市" },
  { Province: 11, ID: 105, Name: "平顶山市" },
  { Province: 11, ID: 106, Name: "安阳市" },
  { Province: 11, ID: 107, Name: "鹤壁市" },
  { Province: 11, ID: 108, Name: "新乡市" },
  { Province: 11, ID: 109, Name: "焦作市" },
  { Province: 11, ID: 110, Name: "济源市" },
  { Province: 11, ID: 111, Name: "濮阳市" },
  { Province: 11, ID: 112, Name: "许昌市" },
  { Province: 11, ID: 113, Name: "漯河市" },
  { Province: 11, ID: 114, Name: "三门峡市" },
  { Province: 11, ID: 115, Name: "南阳市" },
  { Province: 11, ID: 116, Name: "商丘市" },
  { Province: 11, ID: 117, Name: "信阳市" },
  { Province: 11, ID: 118, Name: "周口市" },
  { Province: 11, ID: 119, Name: "驻马店市" },
  { Province: 12, ID: 120, Name: "武汉市" },
  { Province: 12, ID: 121, Name: "黄石市" },
  { Province: 12, ID: 122, Name: "十堰市" },
  { Province: 12, ID: 123, Name: "宜昌市" },
  { Province: 12, ID: 124, Name: "襄阳市" },
  { Province: 12, ID: 125, Name: "鄂州市" },
  { Province: 12, ID: 126, Name: "荆门市" },
  { Province: 12, ID: 127, Name: "孝感市" },
  { Province: 12, ID: 128, Name: "荆州市" },
  { Province: 12, ID: 129, Name: "黄冈市" },
  { Province: 12, ID: 130, Name: "咸宁市" },
  { Province: 12, ID: 131, Name: "随州市" },
  { Province: 12, ID: 132, Name: "恩施土家族苗族自治州" },
  { Province: 12, ID: 133, Name: "仙桃市" },
  { Province: 12, ID: 134, Name: "潜江市" },
  { Province: 12, ID: 135, Name: "天门市" },
  { Province: 12, ID: 136, Name: "神农架林区" },
  { Province: 13, ID: 137, Name: "长沙市" },
  { Province: 13, ID: 138, Name: "株洲市" },
  { Province: 13, ID: 139, Name: "湘潭市" },
  { Province: 13, ID: 140, Name: "衡阳市" },
  { Province: 13, ID: 141, Name: "邵阳市" },
  { Province: 13, ID: 142, Name: "岳阳市" },
  { Province: 13, ID: 143, Name: "常德市" },
  { Province: 13, ID: 144, Name: "张家界市" },
  { Province: 13, ID: 145, Name: "益阳市" },
  { Province: 13, ID: 146, Name: "郴州市" },
  { Province: 13, ID: 147, Name: "永州市" },
  { Province: 13, ID: 148, Name: "怀化市" },
  { Province: 13, ID: 149, Name: "娄底市" },
  { Province: 13, ID: 150, Name: "湘西土家族苗族自治州" },
  { Province: 14, ID: 151, Name: "广州市" },
  { Province: 14, ID: 152, Name: "韶关市" },
  { Province: 14, ID: 153, Name: "深圳市" },
  { Province: 14, ID: 154, Name: "珠海市" },
  { Province: 14, ID: 155, Name: "汕头市" },
  { Province: 14, ID: 156, Name: "佛山市" },
  { Province: 14, ID: 157, Name: "江门市" },
  { Province: 14, ID: 158, Name: "湛江市" },
  { Province: 14, ID: 159, Name: "茂名市" },
  { Province: 14, ID: 160, Name: "肇庆市" },
  { Province: 14, ID: 161, Name: "惠州市" },
  { Province: 14, ID: 162, Name: "梅州市" },
  { Province: 14, ID: 163, Name: "汕尾市" },
  { Province: 14, ID: 164, Name: "河源市" },
  { Province: 14, ID: 165, Name: "阳江市" },
  { Province: 14, ID: 166, Name: "清远市" },
  { Province: 14, ID: 167, Name: "东莞市" },
  { Province: 14, ID: 168, Name: "中山市" },
  { Province: 14, ID: 169, Name: "潮州市" },
  { Province: 14, ID: 170, Name: "揭阳市" },
  { Province: 14, ID: 171, Name: "云浮市" },
  { Province: 15, ID: 172, Name: "南宁市" },
  { Province: 15, ID: 173, Name: "柳州市" },
  { Province: 15, ID: 174, Name: "桂林市" },
  { Province: 15, ID: 175, Name: "梧州市" },
  { Province: 15, ID: 176, Name: "北海市" },
  { Province: 15, ID: 177, Name: "防城港市" },
  { Province: 15, ID: 178, Name: "钦州市" },
  { Province: 15, ID: 179, Name: "贵港市" },
  { Province: 15, ID: 180, Name: "玉林市" },
  { Province: 15, ID: 181, Name: "百色市" },
  { Province: 15, ID: 182, Name: "贺州市" },
  { Province: 15, ID: 183, Name: "河池市" },
  { Province: 15, ID: 184, Name: "来宾市" },
  { Province: 15, ID: 185, Name: "崇左市" },
  { Province: 16, ID: 186, Name: "海口市" },
  { Province: 16, ID: 187, Name: "三亚市" },
  { Province: 16, ID: 188, Name: "五指山市" },
  { Province: 16, ID: 189, Name: "琼海市" },
  { Province: 16, ID: 190, Name: "儋州市" },
  { Province: 16, ID: 191, Name: "文昌市" },
  { Province: 16, ID: 192, Name: "万宁市" },
  { Province: 16, ID: 193, Name: "东方市" },
  { Province: 16, ID: 194, Name: "定安县" },
  { Province: 16, ID: 195, Name: "屯昌县" },
  { Province: 16, ID: 196, Name: "澄迈县" },
  { Province: 16, ID: 197, Name: "临高县" },
  { Province: 16, ID: 198, Name: "白沙黎族自治县" },
  { Province: 16, ID: 199, Name: "昌江黎族自治县" },
  { Province: 16, ID: 200, Name: "乐东黎族自治县" },
  { Province: 16, ID: 201, Name: "陵水黎族自治县" },
  { Province: 16, ID: 202, Name: "保亭黎族苗族自治县" },
  { Province: 16, ID: 203, Name: "琼中黎族苗族自治县" },
  { Province: 16, ID: 204, Name: "西沙群岛" },
  { Province: 16, ID: 205, Name: "南沙群岛" },
  { Province: 16, ID: 206, Name: "中沙群岛的岛礁及其海域" },
  { Province: 17, ID: 207, Name: "重庆市" },
  { Province: 18, ID: 208, Name: "成都市" },
  { Province: 18, ID: 209, Name: "自贡市" },
  { Province: 18, ID: 210, Name: "攀枝花市" },
  { Province: 18, ID: 211, Name: "泸州市" },
  { Province: 18, ID: 212, Name: "德阳市" },
  { Province: 18, ID: 213, Name: "绵阳市" },
  { Province: 18, ID: 214, Name: "广元市" },
  { Province: 18, ID: 215, Name: "遂宁市" },
  { Province: 18, ID: 216, Name: "内江市" },
  { Province: 18, ID: 217, Name: "乐山市" },
  { Province: 18, ID: 218, Name: "南充市" },
  { Province: 18, ID: 219, Name: "眉山市" },
  { Province: 18, ID: 220, Name: "宜宾市" },
  { Province: 18, ID: 221, Name: "广安市" },
  { Province: 18, ID: 222, Name: "达州市" },
  { Province: 18, ID: 223, Name: "雅安市" },
  { Province: 18, ID: 224, Name: "巴中市" },
  { Province: 18, ID: 225, Name: "资阳市" },
  { Province: 18, ID: 226, Name: "阿坝藏族羌族自治州" },
  { Province: 18, ID: 227, Name: "甘孜藏族自治州" },
  { Province: 18, ID: 228, Name: "凉山彝族自治州" },
  { Province: 19, ID: 229, Name: "贵阳市" },
  { Province: 19, ID: 230, Name: "六盘水市" },
  { Province: 19, ID: 231, Name: "遵义市" },
  { Province: 19, ID: 232, Name: "安顺市" },
  { Province: 19, ID: 233, Name: "铜仁地区" },
  { Province: 19, ID: 234, Name: "黔西南布依族苗族自治州" },
  { Province: 19, ID: 235, Name: "毕节地区" },
  { Province: 19, ID: 236, Name: "黔东南苗族侗族自治州" },
  { Province: 19, ID: 237, Name: "黔南布依族苗族自治州" },
  { Province: 20, ID: 238, Name: "昆明市" },
  { Province: 20, ID: 239, Name: "曲靖市" },
  { Province: 20, ID: 240, Name: "玉溪市" },
  { Province: 20, ID: 241, Name: "保山市" },
  { Province: 20, ID: 242, Name: "昭通市" },
  { Province: 20, ID: 243, Name: "丽江市" },
  { Province: 20, ID: 244, Name: "普洱市" },
  { Province: 20, ID: 245, Name: "临沧市" },
  { Province: 20, ID: 246, Name: "楚雄彝族自治州" },
  { Province: 20, ID: 247, Name: "红河哈尼族彝族自治州" },
  { Province: 20, ID: 248, Name: "文山壮族苗族自治州" },
  { Province: 20, ID: 249, Name: "西双版纳傣族自治州" },
  { Province: 20, ID: 250, Name: "大理白族自治州" },
  { Province: 20, ID: 251, Name: "德宏傣族景颇族自治州" },
  { Province: 20, ID: 252, Name: "怒江傈僳族自治州" },
  { Province: 20, ID: 253, Name: "迪庆藏族自治州" },
  { Province: 21, ID: 254, Name: "拉萨市" },
  { Province: 21, ID: 255, Name: "昌都地区" },
  { Province: 21, ID: 256, Name: "山南地区" },
  { Province: 21, ID: 257, Name: "日喀则地区" },
  { Province: 21, ID: 258, Name: "那曲地区" },
  { Province: 21, ID: 259, Name: "阿里地区" },
  { Province: 21, ID: 260, Name: "林芝地区" },
  { Province: 22, ID: 261, Name: "西安市" },
  { Province: 22, ID: 262, Name: "铜川市" },
  { Province: 22, ID: 263, Name: "宝鸡市" },
  { Province: 22, ID: 264, Name: "咸阳市" },
  { Province: 22, ID: 265, Name: "渭南市" },
  { Province: 22, ID: 266, Name: "延安市" },
  { Province: 22, ID: 267, Name: "汉中市" },
  { Province: 22, ID: 268, Name: "榆林市" },
  { Province: 22, ID: 269, Name: "安康市" },
  { Province: 22, ID: 270, Name: "商洛市" },
  { Province: 23, ID: 271, Name: "兰州市" },
  { Province: 23, ID: 272, Name: "嘉峪关市" },
  { Province: 23, ID: 273, Name: "金昌市" },
  { Province: 23, ID: 274, Name: "白银市" },
  { Province: 23, ID: 275, Name: "天水市" },
  { Province: 23, ID: 276, Name: "武威市" },
  { Province: 23, ID: 277, Name: "张掖市" },
  { Province: 23, ID: 278, Name: "平凉市" },
  { Province: 23, ID: 279, Name: "酒泉市" },
  { Province: 23, ID: 280, Name: "庆阳市" },
  { Province: 23, ID: 281, Name: "定西市" },
  { Province: 23, ID: 282, Name: "陇南市" },
  { Province: 23, ID: 283, Name: "临夏回族自治州" },
  { Province: 23, ID: 284, Name: "甘南藏族自治州" },
  { Province: 24, ID: 285, Name: "西宁市" },
  { Province: 24, ID: 286, Name: "海东地区" },
  { Province: 24, ID: 287, Name: "海北藏族自治州" },
  { Province: 24, ID: 288, Name: "黄南藏族自治州" },
  { Province: 24, ID: 289, Name: "海南藏族自治州" },
  { Province: 24, ID: 290, Name: "果洛藏族自治州" },
  { Province: 24, ID: 291, Name: "玉树藏族自治州" },
  { Province: 24, ID: 292, Name: "海西蒙古族藏族自治州" },
  { Province: 25, ID: 293, Name: "银川市" },
  { Province: 25, ID: 294, Name: "石嘴山市" },
  { Province: 25, ID: 295, Name: "吴忠市" },
  { Province: 25, ID: 296, Name: "固原市" },
  { Province: 25, ID: 297, Name: "中卫市" },
  { Province: 26, ID: 298, Name: "乌鲁木齐市" },
  { Province: 26, ID: 299, Name: "克拉玛依市" },
  { Province: 26, ID: 300, Name: "吐鲁番地区" },
  { Province: 26, ID: 301, Name: "哈密地区" },
  { Province: 26, ID: 302, Name: "昌吉回族自治州" },
  { Province: 26, ID: 303, Name: "博尔塔拉蒙古自治州" },
  { Province: 26, ID: 304, Name: "巴音郭楞蒙古自治州" },
  { Province: 26, ID: 305, Name: "阿克苏地区" },
  { Province: 26, ID: 306, Name: "克孜勒苏柯尔克孜自治州" },
  { Province: 26, ID: 307, Name: "喀什地区" },
  { Province: 26, ID: 308, Name: "和田地区" },
  { Province: 26, ID: 309, Name: "伊犁哈萨克自治州" },
  { Province: 26, ID: 310, Name: "塔城地区" },
  { Province: 26, ID: 311, Name: "阿勒泰地区" },
  { Province: 26, ID: 312, Name: "石河子市" },
  { Province: 26, ID: 313, Name: "阿拉尔市" },
  { Province: 26, ID: 314, Name: "图木舒克市" },
  { Province: 26, ID: 315, Name: "五家渠市" },
  { Province: 27, ID: 316, Name: "台北市" },
  { Province: 27, ID: 317, Name: "高雄市" },
  { Province: 27, ID: 318, Name: "台南市" },
  { Province: 27, ID: 319, Name: "台中市" },
  { Province: 27, ID: 320, Name: "金门县" },
  { Province: 27, ID: 321, Name: "南投县" },
  { Province: 27, ID: 322, Name: "基隆市" },
  { Province: 27, ID: 323, Name: "新竹市" },
  { Province: 27, ID: 324, Name: "嘉义市" },
  { Province: 27, ID: 325, Name: "新北市" },
  { Province: 27, ID: 326, Name: "宜兰县" },
  { Province: 27, ID: 327, Name: "新竹县" },
  { Province: 27, ID: 328, Name: "桃园县" },
  { Province: 27, ID: 329, Name: "苗栗县" },
  { Province: 27, ID: 330, Name: "彰化县" },
  { Province: 27, ID: 331, Name: "嘉义县" },
  { Province: 27, ID: 332, Name: "云林县" },
  { Province: 27, ID: 333, Name: "屏东县" },
  { Province: 27, ID: 334, Name: "台东县" },
  { Province: 27, ID: 335, Name: "花莲县" },
  { Province: 27, ID: 336, Name: "澎湖县" },
  { Province: 28, ID: 337, Name: "香港岛" },
  { Province: 28, ID: 338, Name: "九龙" },
  { Province: 28, ID: 339, Name: "新界" },
  { Province: 29, ID: 340, Name: "澳门半岛" },
  { Province: 29, ID: 341, Name: "离岛" },
  { Province: 30, ID: 342, Name: "海外" },
  { Province: 31, ID: 343, Name: "天津市" },
  { Province: 32, ID: 344, Name: "沈阳市" },
  { Province: 32, ID: 345, Name: "大连市" },
  { Province: 32, ID: 346, Name: "鞍山市" },
  { Province: 32, ID: 347, Name: "抚顺市" },
  { Province: 32, ID: 348, Name: "本溪市" },
  { Province: 32, ID: 349, Name: "丹东市" },
  { Province: 32, ID: 350, Name: "锦州市" },
  { Province: 32, ID: 351, Name: "营口市" },
  { Province: 32, ID: 352, Name: "阜新市" },
  { Province: 32, ID: 353, Name: "辽阳市" },
  { Province: 32, ID: 354, Name: "盘锦市" },
  { Province: 32, ID: 355, Name: "铁岭市" },
  { Province: 32, ID: 356, Name: "朝阳市" },
  { Province: 32, ID: 357, Name: "葫芦岛市" },
  { Province: 33, ID: 358, Name: "呼和浩特市" },
  { Province: 33, ID: 359, Name: "包头市" },
  { Province: 33, ID: 360, Name: "乌海市" },
  { Province: 33, ID: 361, Name: "赤峰市" },
  { Province: 33, ID: 362, Name: "通辽市" },
  { Province: 33, ID: 363, Name: "鄂尔多斯市" },
  { Province: 33, ID: 364, Name: "呼伦贝尔市" },
  { Province: 33, ID: 365, Name: "巴彦淖尔市" },
  { Province: 33, ID: 366, Name: "乌兰察布市" },
  { Province: 33, ID: 367, Name: "兴安盟" },
  { Province: 33, ID: 368, Name: "锡林郭勒盟" },
  { Province: 33, ID: 369, Name: "阿拉善盟" },
  { Province: 34, ID: 370, Name: "太原市" },
  { Province: 34, ID: 371, Name: "大同市" },
  { Province: 34, ID: 372, Name: "阳泉市" },
  { Province: 34, ID: 373, Name: "长治市" },
  { Province: 34, ID: 374, Name: "晋城市" },
  { Province: 34, ID: 375, Name: "朔州市" },
  { Province: 34, ID: 376, Name: "晋中市" },
  { Province: 34, ID: 377, Name: "运城市" },
  { Province: 34, ID: 378, Name: "忻州市" },
  { Province: 34, ID: 379, Name: "临汾市" },
  { Province: 34, ID: 380, Name: "吕梁市" },
  { Province: 35, ID: 381, Name: "石家庄市" },
  { Province: 35, ID: 382, Name: "唐山市" },
  { Province: 35, ID: 383, Name: "秦皇岛市" },
  { Province: 35, ID: 384, Name: "邯郸市" },
  { Province: 35, ID: 385, Name: "邢台市" },
  { Province: 35, ID: 386, Name: "保定市" },
  { Province: 35, ID: 387, Name: "张家口市" },
  { Province: 35, ID: 388, Name: "承德市" },
  { Province: 35, ID: 389, Name: "沧州市" },
  { Province: 35, ID: 390, Name: "廊坊市" },
  { Province: 35, ID: 391, Name: "衡水市" },
  { Province: 36, ID: 392, Name: "其他" },
  { Province: 26, ID: 393, Name: "昌吉州呼图壁县" },
  { Province: 11, ID: 394, Name: "长葛市" },
  { Province: 8, ID: 395, Name: "晋江市" },
];

const areaData = {
  country_list: countryData,
  province_list: {},
  city_list: {},
};

// 省
for (let i = 1; i <= countryData.length; i++) {
  for (const provinceItem of provinceData) {
    if (provinceItem.Country === i) {
      if (typeof areaData.province_list[i] === "undefined") {
        areaData.province_list[i] = [];
      }
      areaData.province_list[i].push(provinceItem);
    }
  }
}
// 市
for (const provinceItem of provinceData) {
  for (const cityItem of cityData) {
    if (cityItem.Province === provinceItem.ID) {
      if (typeof areaData.city_list[provinceItem.ID] === "undefined") {
        areaData.city_list[provinceItem.ID] = [];
      }
      areaData.city_list[provinceItem.ID].push(cityItem);
    }
  }
}

// for (const  countryItem of countryData) {
//   areaData.country_list[countryItem.ID] = countryItem
// }

// // 国家
// for (const countryItem of countryData) {
//   areaData.province_list[countryItem.ID] = countryItem.Name
// }

// for (const provinceItem of provinceData) {
//   areaData.city_list[provinceItem.Country] = provinceItem.Name
//   for (const cityitem of cityData) {
//     if (cityitem.Province === provinceItem.ID) {
//       areaData.county_list[provinceItem.Country * 100000 + provinceItem.ID * 100 + cityitem.ID] = cityitem.Name
//     }

//   }
// }
export default areaData;

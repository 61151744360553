<template>
  <div class="common bank-info-page">
    <h2 class="page-title">银行卡资料</h2>
    <!-- 添加银行卡 -->
    <template v-if="isAdd || isDetail">
      <div class="page-content">
        <div class="form form-input">
          <div class="input-div">
            <label for="name" class="form-label">持卡人姓名</label>
            <div class="input">
              <div class="input-content">
                <input
                  type="text"
                  placeholder="请输入持卡人姓名"
                  :value="userInfo.name"
                  readonly
                  :disabled="isDetail"
                />
              </div>
            </div>
          </div>
          <div class="input-div">
            <label for="name" class="form-label">银行卡号</label>
            <div class="input">
              <div class="input-content">
                <input
                  type="text"
                  placeholder="请输入您的银行卡号"
                  form="addBankInfo"
                  v-model="addBankInfo.cardNumb"
                  v-checkParam="rules.cardNumber"
                  @blur="getBankName"
                  :disabled="isDetail"
                />
              </div>
            </div>
          </div>
          <div class="input-div">
            <label for="name" class="form-label">手机号码</label>
            <div class="input">
              <div class="input-content">
                <input
                  type="text"
                  form="addBankInfo"
                  v-model="addBankInfo.mobile"
                  placeholder="请输入您的银行预留手机号码"
                  v-checkParam="rules.phoneNumber"
                  :disabled="isDetail"
                />
              </div>
            </div>
          </div>
          <div class="input-div">
            <label for="name" class="form-label">银行名称</label>
            <div class="input">
              <div class="input-content">
                <input
                  type="text"
                  v-model="addBankInfo.bank"
                  placeholder="请输入您的银行名称"
                  readonly
                  :disabled="isDetail"
                />
              </div>
            </div>
          </div>
          <div class="input-div">
            <label for="name" class="form-label">银行所在地</label>
            <div class="input">
              <div class="input-content" v-if="isWap">
                <input
                  type="text"
                  form="addBankInfo"
                  v-model="bankPosition"
                  placeholder="请输入您的银行所在地"
                  v-checkParam="rules.bankPosition"
                  id="position"
                  @click="showArea = true"
                  :disabled="isDetail"
                />
              </div>
              <el-cascader
                class="select-pc"
                v-if="!isWap && isAdd"
                v-model="bankPosition"
                :options="areaList"
                @change="handleAreaChange"
                :disabled="isDetail"
              ></el-cascader>
              <input
                type="text"
                v-if="!isWap && isDetail"
                v-model="bankPosition"
                :disabled="isDetail"
              />
            </div>
          </div>
          <div class="input-div">
            <label for="name" class="form-label">分/支行名称</label>
            <div class="input">
              <div class="input-content">
                <input
                  type="text"
                  form="addBankInfo"
                  v-model="addBankInfo.branchName"
                  placeholder="请输入您的分/支行名称"
                  v-checkParam="rules.branchName"
                  :disabled="isDetail"
                />
              </div>
            </div>
          </div>
          <div class="input-div" v-if="isDetail">
            <label for="name" class="form-label">审核状态</label>
            <div class="input">
              <div class="input-content">
                <input
                  type="text"
                  v-if="addBankInfo.bankCardStatus == 1"
                  value="审核中，请等待"
                  class="status-1"
                  disabled
                />
                <input
                  type="text"
                  v-if="addBankInfo.bankCardStatus == 3"
                  value="审核未通过"
                  class="status-3"
                  disabled
                />
                <i
                  v-if="addBankInfo.bankCardStatus == 2"
                  class="succ disabled"
                ></i>
                <input
                  type="text"
                  v-if="addBankInfo.bankCardStatus == 2"
                  value="已通过"
                  class="status-2"
                  disabled
                />
              </div>
            </div>
          </div>
          <!-- 图片信息 -->
          <div
            class="high-upload"
            v-if="(bankSwitch == 1 && isAdd) || addBankInfo.bankCardImg"
          >
            <van-uploader
              class="uploader uploader_01"
              :after-read="onRead"
              :max-size="5242800"
              @oversize="overSize"
              form="addBankInfo"
              upload-icon=""
              :style="
                addBankInfo.bankCardImg
                  ? `background-image: url('${addBankInfo.bankCardImg}')`
                  : ''
              "
            >
            </van-uploader>
          </div>
        </div>
        <button
          v-if="isAdd"
          class="button"
          v-checkSubmit="'addBankInfo'"
          :submit="handleAddBank"
          :disabled="disabled"
        >
          提交
        </button>
        <div class="btn-div" v-if="isDetail">
          <button class="button bind-btn" @click="handleBind(addBankInfo.id)">
            解除绑定
          </button>
          <button class="button back-btn" @click="handleBack">返回</button>
        </div>
      </div>
    </template>

    <!-- 已绑定银行卡 -->
    <template v-if="!isAdd && !isDetail">
      <div class="page-content div-list">
        <div class="bank-list">
          <div
            class="bank-info"
            v-for="item in bankList"
            :key="item.id"
            @click="handleShowDetail(item.id)"
          >
            <div class="bank-title">
              <i></i>
              <span>{{ item.bank }}</span>
            </div>
            <p class="bank-num">{{ item.cardNumb }}</p>
            <p class="bank-name">持卡人姓名：{{ userInfo.name }}</p>
            <p class="bank-addr">
              银行所在地：{{ item.bankProvice }}{{ item.bankCity
              }}{{ item.branchName }}
            </p>
          </div>
        </div>

        <button
          v-if="this.bankList.length < 3"
          class="button base-btn"
          @click="handleAddNewBank"
        >
          添加银行卡
        </button>
      </div>
    </template>
    <van-popup v-model="showArea" position="bottom">
      <van-picker
        value-key="Name"
        show-toolbar
        :columns="areaList"
        @change="onChange"
        @cancel="showArea = false"
        @confirm="confirm"
      />
      <!-- <van-picker  :area-list="areaList" @cancel='_cancel' @confirm="confirm" /> -->
    </van-popup>
    <Dialog
      :dialogObj="dialogObj"
      @confirm="handleDialogConfirm"
      @cancel="handleDialogCancel"
    />
  </div>
</template>

<script>
import account from "@/apis/account.js";
import Dialog from "@/components/dialog.vue";
import areaData from "@/utils/area.js";
import { oss } from "@/mixins/ossUpload.js";
export default {
  name: "Bank",
  components: {
    Dialog,
  },
  mixins: [oss],
  data() {
    let bankCardImg = (value, callback) => {
      if (this.addBankInfo.bankCardImg === "") {
        callback("请上传银行卡正面");
      }
    };
    let bankposition = (value, callback) => {
      if (this.bankPosition === "") {
        callback("请选择银行所在地");
      }
    };
    return {
      isDetail: false, //是否查看银行卡详情
      isAdd: false, //是否添加银行卡
      addBankInfo: {
        cardNumb: "", //银行卡账号
        bank: "", //银行卡名称
        countryType: "", //所属国家（中国大陆1,中国香港2,中国澳门3,中国台湾4,其他国家及地区5）
        bankProvice: "", //省
        bankCity: "", //市
        bankAddress: "", //地址
        branchName: "", //分/支行名称
        bankCardImg: "",
        mobile: "", //手机
      },
      bankId: "", //所属银行卡名称id
      bankList: [], //已填加的银行卡信息
      bankSwitch: "", //四要素是否开启
      bankPosition: "",
      showArea: false, //是否显示选择区域
      rules: {
        cardNumber: [
          { rule: "required", msg: "银行卡号不能为空!" },
          { rule: /^([1-9]{1})(\d{15}|\d{18})$/, msg: "银行卡格式错误" },
        ],
        phoneNumber: [
          { rule: "required", msg: "手机号码不能为空!" },
          { rule: /^1[3456789]\d{9}$/, msg: "手机号码格式错误" },
        ],
        bankPosition: [bankposition],
        branchName: [{ rule: "required", msg: "请输入分支行名称!" }],
        bankCardImg: [bankCardImg],
      },
      areaIndex: 1,
      disabled: true, //是否可以点击提交
      dialogObj: {},
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.login.userInfo;
    },
    isWap() {
      return this.$store.state.common.isWap;
    },
    areaList() {
      if (this.isWap) {
        return [
          {
            values: areaData.country_list,
          },
          {
            values: areaData.province_list[1],
          },
          {
            values: areaData.city_list[1],
          },
        ];
      } else {
        console.log(areaData);
        let area = [];
        for (const item of areaData.country_list) {
          //area.push(item);
          area.push({
            value: item.ID,
            label: item.Name,
            children: [],
          });
        }
        //let provinceList = Object.values(areaData.province_list);
        //console.log(provinceList);
        let provinceData = [];
        for (let i in area) {
          for (let j in areaData.province_list) {
            if (j == area[i].value) {
              provinceData = areaData.province_list[j];
              for (let provinceItem of provinceData) {
                let data = {
                  value: provinceItem.ID,
                  label: provinceItem.Name,
                  children: [],
                };
                for (let k in areaData.city_list) {
                  let cityData = areaData.city_list[k];
                  for (let cityItem of cityData) {
                    if (cityItem.Province === provinceItem.ID) {
                      data.children.push({
                        value: cityItem.ID,
                        label: cityItem.Name,
                      });
                    }
                  }
                }
                area[i]["children"].push(data);
              }
            }
          }
        }
        return area;
      }
    },
  },
  methods: {
    //获取四要素是否开启
    getBankSwitch() {
      account.bankSwitch().then((res) => {
        if (res.IsSuccess) {
          this.bankSwitch = res.Data;
        }
      });
    },
    //获取是否添加银行卡
    getBankList() {
      account.bankList().then((res) => {
        if (res.IsSuccess) {
          this.bankList = res.Data;
        } else {
          this.isAdd = true;
          this.disabled = false;
        }
      });
    },
    onChange(picker, values) {
      console.log(picker, values);
      if (this.areaIndex === values[0].ID) {
        picker.setColumnValues(1, areaData.province_list[values[0].ID]);
        picker.setColumnValues(2, areaData.city_list[values[1].ID]);
      } else {
        picker.setColumnValues(1, areaData.province_list[values[0].ID]);
        picker.setColumnValues(
          2,
          areaData.city_list[areaData.province_list[values[0].ID][0].ID]
        );
      }
      this.areaIndex = values[0].ID;
    },
    //选择所在地
    confirm(picker) {
      console.log(picker);
      this.bankPosition =
        picker[0].Name + "/" + picker[1].Name + "/" + picker[2].Name;
      this.addBankInfo.countryType = picker[0]["ID"];
      this.addBankInfo.bankProvice = picker[1]["ID"];
      this.addBankInfo.bankCity = picker[2]["ID"];
      this.showArea = false;
      document.getElementById("position").focus();
      document.getElementById("position").blur();
    },
    //根据卡号获取银行名称
    getBankName() {
      let str = this.addBankInfo.cardNumb.replace(/\s+/g, "");
      let val = str.slice(0, 6);
      if (val.length >= 6) {
        let params = {
          cardNumb: val,
        };
        account
          .bankName(params)
          .then((res) => {
            if (res.IsSuccess) {
              this.addBankInfo.bank = res.Data[0].bankName;
              this.bankId = res.Data[0].bankId;
            }
          })
          .catch((err) => {
            console.info(err);
          });
      }
    },
    //点击提交银行卡信息按钮
    handleAddBank() {
      if (this.disabled) {
        return;
      }
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "提交中...",
      });
      let params = Object.assign({}, this.addBankInfo, { bank: this.bankId });
      //关闭四要素时需上传图片
      if (this.bankSwitch == 1 && !this.addBankInfo.bankCardImg) {
        this.$toast.clear();
        this.$toast("请先上传银行卡正面照");
        return;
      }
      this.disabled = true;
      account.addBankCard(params).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.handleDialog();
        } else {
          this.disabled = false;
          this.$toast(res.Data);
          if (res.Data.indexOf("最多同时绑定") > 0) {
            //绑定超过3张卡
            this.isAdd = false;
            this.disabled = true;
          }
        }
      });
    },
    handleDialog() {
      this.dialogObj = {
        isShow: true,
        title: "",
        content: "银行卡已绑定成功",
        showBankImg: true,
        confirmText: "返回首页",
        cancelText: "进入个人资料",
      };
    },
    handleDialogConfirm() {
      this.$router.push({
        name: "index",
      });
    },
    handleDialogCancel() {
      this.getBankList();
      this.isAdd = false;
      this.disabled = true;
    },
    handleAddNewBank() {
      if (this.bankList.length >= 3) {
        this.$toast("同一用户最多同时绑定3张银行");
        return;
      } else {
        this.addBankInfo = {
          cardNumb: "", //银行卡账号
          bank: "", //银行卡名称
          countryType: "", //所属国家（中国大陆1,中国香港2,中国澳门3,中国台湾4,其他国家及地区5）
          bankProvice: "", //省
          bankCity: "", //市
          bankAddress: "", //地址
          branchName: "", //分/支行名称
          bankCardImg: "",
          mobile: "", //手机
        };
      }
      this.isAdd = true;
      this.disabled = false;
    },
    //获取银行卡信息
    handleShowDetail(id) {
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "加载中...",
      });
      account.bankCardDetail({ id: id }).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.addBankInfo = res.Data;
          this.bankPosition =
            res.Data.countryType +
            "/" +
            res.Data.bankProvice +
            "/" +
            res.Data.bankCity;
          this.isDetail = true;
          this.disabled = true;
        } else {
          this.$toast(res.Data);
        }
      });
    },
    handleAreaChange(event) {
      this.addBankInfo.countryType = event[0];
      this.addBankInfo.bankProvice = event[1];
      this.addBankInfo.bankCity = event[2];
    },
    //设置图片  type=1  正面地址  type=2 反面地址
    setImgUrl(url) {
      this.addBankInfo.bankCardImg = url;
    },
    // 上传银行卡
    onRead(file) {
      if (this.isDetail) {
        return;
      }
      this.doUpload(file.file, 1);
    },
    handleBack() {
      this.isDetail = false;
    },
    //解除绑定
    handleBind(id) {
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "解绑中...",
      });
      account.cancelBindCard({ id: id }).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.$toast(res.Data);
          this.getBankList();
          this.isDetail = false;
        } else {
          this.$toast(res.Data);
        }
      });
    },
  },
  created() {
    //获取四要素是否开启
    this.getBankSwitch();

    //获取是否添加银行卡
    this.getBankList();
  },
  mounted() {
    //防止后台已审核银行卡，但点击取款菜单时信息未更新（为从新获取信息），提示绑定卡，所以此处更新
    //更新用户信息
    this.$store.dispatch("login/getUserInfo", {
      password: this.$store.state.login.userInfo.password,
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/scss/account/bank.scss";
</style>
